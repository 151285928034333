import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 152px;
  padding: 64px 0;
  color: #ce9b61;
  background-color: #141f35;

  &::before {
    position: absolute;
    top: -7px;
    display: block;
    content: "";
    background-position: -20px -21px;
    width: 100%;
    height: 7px;
    z-index: 1;

    @media (max-width: 1023px) {
      background-image: radial-gradient(circle, transparent 5px, #141f35 5px);
      background-size: 14px 14px;
    }

    @media (min-width: 1024px) {
      background-image: radial-gradient(circle, transparent 6px, #141f35 7px);
      background-size: 20px 14px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 1023px) {
    justify-content: center;
  }

  @media (min-width: 1024px) {
    justify-content: space-around;
  }
`;

export const NavList = styled.ul`
  text-align: center;

  @media (max-width: 1023px) {
    margin: 0 28px;
  }
`;

export const NavLink = styled.a`
  font-family: "DharmaGothic";
  font-size: 21px;
  line-height: 31px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const Copyright = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Copy = styled.p`
  margin: 32px 0;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 24px;
`;

export const Logo = styled.img`
  height: 25px;
`;

export const LogoMobile = styled.div`
  margin-top: 56px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const LogoOleSmoky = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DharmaGothic";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.26em;

  img {
    margin-right: 8px;
    margin-bottom: 4px;
    margin-left: 8px;
  }
`;

export const Subscribe = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-family: "GreatWestern";
  font-size: 14px;
  letter-spacing: 0.05em;
`;

export const Follow = styled.span`
  margin-left: 29px;
`;
