import React from 'react';
import { Link } from 'gatsby';

import {
  Root,
  Container,
  NavList,
  NavLink,
  Copyright,
  Copy,
  Logo,
  LogoMobile,
  LogoOleSmoky,
  Subscribe,
  Social,
  Follow,
} from './Footer.style';

import LogoSrc from '../../images/logo-footer.svg';
import OleSmokySrc from '../../images/ole-smoky.svg';

import SocialList from '../SocialList/SocialList';
import SubscribeForm from '../SubscribeForm/SubscribeForm';

const Footer = () => {
  return (
    <Root>
      <Container>
        <NavList>
          <li>
            <NavLink as={Link} to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              target="_blank"
              as={Link}
              to="https://olesmoky.com/collections/james-ownby-reserve/products/james-ownby"
            >
              Shop
            </NavLink>
          </li>
          <li>
            <NavLink as={Link} to="/history">
              History
            </NavLink>
          </li>
          <li>
            <NavLink as={Link} to="/contact">
              Contact
            </NavLink>
          </li>
        </NavList>

        <NavList>
          {/* <li>
            <NavLink as={Link} to="/">
              SOCIAL RESPONSIBILITY
            </NavLink>
          </li> */}
          <li>
            <NavLink as={Link} to="/privacy">
              PRIVACY POLICY
            </NavLink>
          </li>
          <li>
            <NavLink as={Link} to="/terms">
              TERMS AND CONDITIONS
            </NavLink>
          </li>
          <li>
            <NavLink as={Link} to="/cookie-policy">
              COOKIE POLICY
            </NavLink>
          </li>
          {/* <li>
            <NavLink as={Link} to="/">
              FAQ'S
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink as={Link} to="/">
              MEDIA
            </NavLink>
          </li> */}
        </NavList>

        <Copyright>
          <Link to="/">
            <Logo src={LogoSrc} alt="" />
          </Link>
          <Copy>
            BOTTLED&nbsp;BY
            <br />
            OLE&nbsp;SMOKY&nbsp;DISTILLERY,&nbsp;LLC
            <br />
            GATLINBURG,&nbsp;TN
            <br />
            ©&nbsp;{new Date().getFullYear()}
            &nbsp;OLE&nbsp;SMOKY&nbsp;DISTILLERY
          </Copy>
          <LogoOleSmoky href="https://olesmoky.com/" target="_blank">
            <span>BOTTLED BY</span>
            <img src={OleSmokySrc} alt="" />
            <span>DISTILLERY</span>
          </LogoOleSmoky>
        </Copyright>

        <Subscribe>
          <SubscribeForm />

          <Social>
            <SocialList />
            <Follow>FOLLOW US</Follow>
          </Social>
        </Subscribe>
      </Container>

      <LogoMobile>
        <Link as={Link} to="/">
          <Logo src={LogoSrc} alt="" />
        </Link>
      </LogoMobile>
    </Root>
  );
};

export default Footer;
